html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
  background-color: #000000;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.App {
  text-align: center;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
}

.content {
  position: relative;
  z-index: 2;
}

/* Landing Section */
.landing-section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.landing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  position: relative;
  padding: 0 20px;
}

.nihaya-title {
  font-size: 4rem;
  color: white;
  margin: 0 0 2rem 0;
  letter-spacing: 0.05em;
  text-align: center;
}

.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 3rem;
}

.form-group {
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: row;
  max-width: 400px;
  margin: 0 auto;
}

.email-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 4px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 1rem;
  min-width: 0; /* Prevents flex item from overflowing */
}

.submit-button {
  padding: 0.75rem 2rem;
  background: white;
  color: black;
  border: none;
  border-radius: 4px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

/* Scroll Indicator */
.scroll-indicator {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  cursor: pointer;
  z-index: 10;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-indicator.fade-out {
  opacity: 0;
  transform: translateX(-50%) translateY(20px);
  pointer-events: none;
}

.scroll-text {
  color: white;
  font-family: "Futura", sans-serif;
  font-size: 14px;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.scroll-arrow {
  width: 12px;
  height: 12px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(45deg);
  animation: bounce 2s infinite;
  margin-top: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* About Section */
.about-section {
  background: #000000;
  padding: 4rem 0;
  position: relative;
  margin-top: 0;
  z-index: 2;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
  color: white;
}

.about-title,
.about-subtitle,
.mask-section h3 {
  font-family: 'ObjectMedium', sans-serif;
  margin-bottom: 1.5rem;
  text-align: center;
  color: white;
}

.about-title {
  font-size: 2.5rem;
  letter-spacing: 0.05em;
}

.about-subtitle {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}

.about-content p {
  font-family: "Futura", sans-serif;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: white;
}

.quote-block {
  margin: 2rem 0;
}

.quote-block blockquote {
  font-family: "Futura", sans-serif;
  font-style: italic;
  padding-left: 1.5rem;
  border-left: 3px solid white;
  margin: 1rem 0;
  color: white;
}

.design-credits {
  text-align: center;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0;
  box-sizing: border-box;
  color: white;
}

.mask-section h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 2rem 0;
}

.features-list {
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;
  color: white;
}

.features-list li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  font-family: "Futura", sans-serif;
}

.features-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: white;
}

.swarovski-section {
  margin: 2rem 0;
  color: white;
}

.final-details {
  margin-top: 2rem;
  color: white;
}

/* Animations */
@keyframes bounce {
  0%, 100% { transform: rotate(45deg) translate(-2px, -2px); }
  50% { transform: rotate(45deg) translate(2px, 2px); }
}

/* Form Styles */
.form-group {
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: row;
}

.email-input {
  flex: 1;
  width: auto;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 4px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
}

.email-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.submit-button {
  padding: 0.75rem 2rem;
  background: white;
  color: black;
  border: none;
  border-radius: 4px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.submit-button:hover {
  background: rgba(255, 255, 255, 0.9);
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-message {
  color: #ff4757;
  text-align: center;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 1rem;
  background: rgba(255, 71, 87, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 71, 87, 0.2);
  margin-top: 1rem;
}

.success-message {
  color: white;
  text-align: center;
  padding: 0.75rem 1.5rem;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 1.2rem;
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .nihaya-title {
    font-size: 3rem;
    padding: 0 20px;
  }

  .form-group {
    flex-direction: column;
  }

  .form-container {
    padding: 0 20px;
  }

  .submit-button {
    width: 100%;
  }

  .about-section {
    padding: 3rem 20px;
  }

  .about-content {
    padding: 0;
  }

  .nihaya-key-container {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 20px;
    margin: 0 20px;
  }

  .nihaya-text {
    font-size: 3rem;
    text-align: center;
  }

  .key-section .design-image {
    width: 120px;
  }

  .image-section {
    padding: 0 20px;
  }

  .design-credits {
    margin: 2rem auto;
    padding: 0;
  }

  .scroll-indicator {
    bottom: 60px;
  }

  .scroll-text {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .scroll-arrow {
    width: 10px;
    height: 10px;
    border-width: 2px;
  }

  .rain-video {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #000;
  }
}

/* Small Mobile Styles */
@media screen and (max-width: 480px) {
  .nihaya-title {
    font-size: 2.5rem;
  }

  .nihaya-text {
    font-size: 2.5rem;
  }

  .key-section .design-image {
    width: 100px;
  }

  .about-section {
    padding: 2rem 20px;
  }

  .form-container,
  .image-section,
  .nihaya-key-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.design-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.design-image:hover {
  transform: scale(1.02);
}

.image-section p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: white;
  max-width: 80%;
}

@font-face {
  font-family: 'ObjectMedium';
  src: url('./assets/fonts/object-medium.ttf') format('truetype');
}

.object-font {
  font-family: 'ObjectMedium', sans-serif;
  font-weight: normal;
}

.nihaya-title {
  font-size: 4rem;
  color: white;
  margin-bottom: 2rem;
  letter-spacing: 0.05em;
}

/* Key section specific styles */
.key-section {
  margin: 3rem 0;
}

.nihaya-key-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  background: #000000;
  padding: 3rem 20px;
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.nihaya-key-container:hover {
  transform: scale(1.02);
}

.nihaya-text {
  color: white;
  font-size: 5rem;
  letter-spacing: 0.05em;
  font-style: normal;
  font-family: 'ObjectMedium', sans-serif;
  font-weight: normal;
}

.key-section .design-image {
  width: 200px;
  height: auto;
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.key-section .design-image:hover {
  transform: none;
}

.design-credits .key-section p {
  font-style: normal;
  font-family: 'Futura', sans-serif;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .nihaya-key-container {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 20px;
    margin: 0 20px;
  }

  .nihaya-text {
    font-size: 3rem;
    text-align: center;
  }

  .key-section .design-image {
    width: 120px;
  }
}

/* Video Background */
.rain-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background: #000000;
  will-change: transform;
  backface-visibility: hidden;
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .rain-video {
    position: fixed;
    height: -webkit-fill-available;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Product Section */
.product-section {
  background: #000000;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.product-container {
  max-width: 1600px;
  margin: 0 auto;
}

.product-layout {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  min-height: 100vh;
}

.product-gallery {
  position: relative;
  height: 100vh;
  background: #000000;
}

.carousel-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #000000;
}

.carousel-image-container {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: #000000;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: calc(100vh - 160px);
  display: block;
}

.carousel-navigation {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: #000000;
  margin-top: auto;
}

.nav-button {
  background: none;
  border: none;
  color: white;
  font-family: 'ObjectMedium', sans-serif;
  cursor: pointer;
  padding: 0.5rem 1rem;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.nav-button:hover {
  opacity: 1;
}

.image-counter {
  color: white;
  font-family: 'ObjectMedium', sans-serif;
}

.product-info {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 100vh;
  background: #000000;
  padding: 2rem;
}

.info-scroll {
  padding: 4rem 2rem;
}

.product-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: white;
}

.price-section {
  margin-bottom: 3rem;
}

.price {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
}

.variant-selector {
  margin-bottom: 3rem;
}

.variant-label {
  display: block;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 1rem;
  font-family: 'ObjectMedium', sans-serif;
  font-size: 0.9rem;
}

.mask-switch {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.switch-button {
  padding: 1rem;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-family: 'ObjectMedium', sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
}

.switch-button.active {
  background: white;
  color: black;
}

.switch-button:hover:not(.active) {
  border-color: white;
}

.soldout-button {
  width: 100%;
  padding: 1rem;
  background: #FF3B3B;;
  border: 1px solid #FF3B3B;
  color: white;
  font-family: 'ObjectMedium', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.soldout-button:hover {
  background: #FF3B3B;
  color: white;
}

.product-description {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.description-section h3 {
  color: white;
  font-family: 'ObjectMedium', sans-serif;
  margin-bottom: 1rem;
}

.description-section ul {
  list-style: none;
  padding: 0;
}

.description-section li {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  .product-section {
    min-height: auto;
    overflow: visible;
  }

  .product-layout {
    grid-template-columns: 1fr;
  }

  .product-gallery {
    height: auto;
    position: relative;
    overflow: visible;
    order: 2;
  }

  .product-info {
    order: 1;
    border-left: none;
    border-top: none;
    padding: 2rem;
  }

  .carousel-container {
    height: auto;
  }

  .carousel-image-container {
    height: 70vh;
    padding: 1rem;
  }

  .carousel-image {
    max-height: calc(70vh - 60px);
  }

  .carousel-navigation {
    padding: 0 1rem;
  }

  .carousel-container {
    touch-action: pan-y pinch-zoom;
    -webkit-overflow-scrolling: touch;
  }

  .carousel-slide.transitioning {
    transition: transform 0.3s ease-out;
  }
}

@media screen and (max-width: 1024px) {
  .about-section {
    margin-top: 0;
  }
}

.site-footer {
  background-color: #000000;
  padding: 60px 40px 20px;
  color: #fff;
  position: relative;
  z-index: 2;
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 40px;
  max-width: 1400px;
  margin: 0 auto;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-left a,
.footer-right a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  transition: opacity 0.2s;
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
}

.footer-left a:hover,
.footer-right a:hover {
  opacity: 0.8;
}

.footer-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.footer-logo {
  width: 80px;
  height: auto;
}

.social-links {
  display: flex;
  gap: 20px;
}

.social-links a {
  color: #fff;
  font-size: 24px;
  transition: opacity 0.2s;
}

.social-links a:hover {
  opacity: 0.8;
}

.newsletter-signup {
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.newsletter-signup h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
}

.newsletter-signup p {
  font-size: 14px;
  color: #999;
  margin-bottom: 20px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
}

.newsletter-signup form {
  display: flex;
  gap: 10px;
}

.newsletter-signup input {
  flex: 1;
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
}

.newsletter-signup input::placeholder {
  color: #999;
}

.newsletter-signup button {
  padding: 10px 20px;
  background: #fff;
  color: #1C1C1C;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s;
}

.newsletter-signup button:hover {
  opacity: 0.9;
}

.footer-bottom {
  text-align: center;
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom p {
  font-size: 15px !important;
  font-family: 'ObjectMedium', sans-serif;
  margin: 0;
}

.footer-bottom p a {
  font-size: 15px !important;
  font-family: 'ObjectMedium', sans-serif;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .footer-left,
  .footer-right {
    align-items: center;
  }

  .newsletter-signup form {
    flex-direction: column;
  }

  .newsletter-signup input,
  .newsletter-signup button {
    width: 100%;
  }
}

.footer-right {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-size: 20px;
  color: #fff;
  line-height: 0;
}

.footer-right p {
  transition: opacity 0.2s;
}

.footer-right p:hover {
  opacity: 0.8;
}

.footer-left {
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 1.2;
}