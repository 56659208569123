.company-info-page {
  min-height: 100vh;
  background-color: #000000;
  color: white;
  padding: 40px 20px;
}

.company-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.back-button {
  color: white;
  text-decoration: none;
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-size: 18px;
  transition: opacity 0.2s;
}

.back-button:hover {
  opacity: 0.8;
}

.company-logo {
  width: 60px;
  height: auto;
}

.company-info-content {
  max-width: 800px;
  margin: 60px auto;
  padding: 0 20px;
}

.company-info-content h1 {
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-size: 36px;
  margin-bottom: 40px;
  text-align: center;
}

.info-section {
  display: grid;
  gap: 40px;
}

.info-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
}

.info-item h2 {
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-size: 24px;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.info-item p {
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 20px;
  color: white;
}

@media (max-width: 768px) {
  .company-info-content h1 {
    font-size: 28px;
  }

  .info-item h2 {
    font-size: 20px;
  }

  .info-item p {
    font-size: 18px;
  }
} 