.privacy-policy-page {
  min-height: 100vh;
  background-color: #000000;
  color: white;
  padding: 40px 20px;
}

.privacy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.back-button {
  color: white;
  text-decoration: none;
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-size: 18px;
  transition: opacity 0.2s;
}

.back-button:hover {
  opacity: 0.8;
}

.company-logo {
  width: 60px;
  height: auto;
}

.privacy-content {
  max-width: 800px;
  margin: 60px auto;
  padding: 0 20px;
}

.privacy-content h1 {
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-size: 36px;
  margin-bottom: 10px;
  text-align: center;
}

.effective-date {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 40px;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
}

.policy-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.intro {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

section {
  margin-bottom: 30px;
}

h2 {
  font-family: "Didot", "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-size: 24px;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.9);
}

p {
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

li {
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 8px;
}

a {
  color: white;
  text-decoration: underline;
  transition: opacity 0.2s;
}

a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .privacy-content h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  p, li {
    font-size: 15px;
  }
} 